<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    v-model="drawer"
    :title="listQuery.comName + '-未认证发票'"
    width="1100px"
  >
    <div class="top_select">
      <div class="hint">
        <i class="iconfont icon-gantanhao1"></i>请勾选需要认证的发票
      </div>
    </div>
    <el-table
      :data="list"
      border
      :height="contentStyleObj"
      style="width: 100%"
      stripe
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column align="center" prop="period" label="账期" width="80" />
      <el-table-column
        align="center"
        prop="invoice_date"
        label="开票日期"
        width="100"
      >
        <template #default="scope">
          <span>{{ $parseTime(scope.row.invoice_date, "{y}-{m}-{d}") }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="fpdm"
        label="发票代码"
        width="180"
      />
      <el-table-column
        align="center"
        prop="fphm"
        label="发票号码"
        width="180"
      />
      <el-table-column align="center" prop="corpName" label="公司名称" />
      <el-table-column align="center" prop="invoiceType" label="发票类型">
        <template #default="scope">
          {{ filterText(scope.row.invoiceType) }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="sumAmount" label="金额" />
      <el-table-column align="center" prop="sumTax" label="税额" />
      <el-table-column align="center" prop="sumTotal" label="价税合计" />
    </el-table>
    <div class="dialog-footer">
      <div>
        <qzf-pagination
          v-show="total > 0"
          :total="total"
          v-model:page="listQuery.page"
          v-model:limit="listQuery.limit"
          @pagination="getList"
        />
      </div>
      <div>
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="batchSure()"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { inInvoiceWzzList } from "@/api/invoice";
import { sendTask } from "@/api/collection";
const { proxy } = getCurrentInstance();
const emit = defineEmits();
const props = defineProps({
  period: {
    type: String,
    default: "",
  },
});
const listQuery = ref({
  page: 1,
  limit: 20,
  status: 3,
  comId: null,
  comName: "",
});
const selects = ref([]);
const loading = ref(false);
const list = ref([]);
const total = ref(0);
const drawer = ref(false);
const contentStyleObj = ref({});
const btnLoading = ref(false);
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(300);
});
const init = (row) => {
  listQuery.value.comId = row.comId;
  listQuery.value.comName = row.name;
  drawer.value = true;
  getList();
};
const getList = () => {
  loading.value = true;
  inInvoiceWzzList(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list;
      total.value = res.data.data.total;
    }
  });
};
const handleSelectionChange = (e) => {
  selects.value = e;
};
const batchSure = () => {
  if (selects.value.length == 0) {
    proxy.$qzfMessage("请选择需要认证的发票！", 1);
    return;
  } else {
    btnLoading.value = true;
    let param = {
      comIds: [listQuery.value.comId],
      period: props.period,
      taskName: "tax-gxrz",
      cond: {
        invoice: selects.value,
      },
    };
    sendTask(param).then((res) => {
      btnLoading.value = false;
      if (res.data.msg == "success") {
        proxy.$qzfMessage("发起成功");
        drawer.value = false;
        emit("success");
      }
    });
  }
};
const filterText = (type) => {
  if (type == "1") {
    return "专票";
  } else if (type == "2") {
    return "普票";
  } else if (type == "3") {
    return "农产品发票";
  } else if (type == "4") {
    return "电子专票";
  } else if (type == "5") {
    return "电子普票";
  } else if (type == "6") {
    return "海关缴款书";
  } else if (type == "7") {
    return "其他发票";
  }
};

defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.hint {
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
  .icon-gantanhao1 {
    font-size: 13px;
    margin-right: 3px;
  }
}
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.dialog-footer {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
