<template>
  <div style="position: relative;">
   <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="税款申报" name="税款申报"></el-tab-pane>
      <el-tab-pane label="零申报" name="零申报"></el-tab-pane>
      <div class="top_btns">
        <div>
          <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
          <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>
          <el-input placeholder="请输入企业名称/编号" v-model="listQuery.name" style="width: 160px;margin-left:5px" size="small" @keyup.enter="getList" clearable/>
          <el-input v-model="listQuery.jotDown" style="width:130px;margin-left: 5px;" size="small" @keyup.enter="getList" clearable placeholder="请输入随手记" />
          <el-button type="primary" size="small" @click="getList" icon="Search">搜索</el-button>
        </div>
      </div>
      <el-table stripe :data="list" :height="contentStyleObj" border @select="handleSelectionChange"  v-loading="listLoading" @sort-change="sortChange" ref="tableScroll" id="tableLazyLoad" @select-all="handleSelectionChangeAll">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column  label="编号" align="center" prop="sort" width="80" fixed="left" sortable="custom">
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column align="left" prop="name" fixed label="公司名称" min-width="300" class="gsmc">
          <template #default="scope">
            <TagNameCopy :row="scope.row"  idKey="comId" :showAuthIcon="false"></TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column  prop="district" label="税局" width="65">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district)  }}</span>  
          </template>
        </el-table-column>
        <el-table-column prop="jotDown" label="随手记" min-width="150" show-overflow-tooltip>
          <template #default="scope">
            <span class="xgmStyle" style="cursor:pointer;" @click="smallNotes(scope.row)">记</span>
            <span>{{scope.row.jotDown}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" min-width="180">
          <template #default="scope">
            <el-button @click="showOcrImg(scope.row)" size="small" type="primary" link>附件上传</el-button>
            <el-button @click="handleCheckDetail(scope.row)" size="small" type="primary" link>明细</el-button>
          </template>
        </el-table-column>
      </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList"/>
    </div>
    </el-tabs>

    <OctImgDialog ref="octImgDialogRef" />
    <UploadList ref="uploadListRef" />
    <jotDown ref="jotDown" @jotDownUpdate="updateNote"/>
  </div>
</template>

<script>
import { currentAccountPeriod } from '@/utils'
import { electronicBookList } from "@/api/company";
import OctImgDialog from './components/ocr-upload/OcrImgDialog.vue'
import UploadList from './components/ocr-upload/UploadList.vue'
import jotDown from '@/components/jotDown'
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
export default {
  name: "attachmentUpload",
  components: {
    OctImgDialog,
    UploadList,
    jotDown,
    TagNameCopy,
    TableSortCell
  },
  data(){
    return {
      activeName:'税款申报',
      listQuery: {
        page: 1,
        limit: 20,
        accountingType: 2,
        status: "2",
        companyType: "",
        period: currentAccountPeriod(),
      },
      total:0,
      list:[],
      listLoading:false,
      totalPage:0,
      pageSize:50,
      tableData:[],
      currentPage:1,
      allChecked:false,
      selects:[]
    }
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(251)
  },
  methods:{
    smallNotes(row){
      this.$refs.jotDown.init(row.name,row.comId,this.listQuery.period)
    },
    //更新随手记之后的回调
    updateNote(id,text){
      this.list.map(v=>{
        if(v.comId == id){
          v.jotDown = text
        }
      })
    },
    showOcrImg(row) {
      this.$refs.octImgDialogRef.init(row)
    },
    // 查看明细
    handleCheckDetail(row){
      this.$refs.uploadListRef.show(row)
    },
    getList(){
      this.listLoading = true
      electronicBookList(this.listQuery).then(res=>{
        this.listLoading = false
        this.tableData = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total
        this.setScroll()
      })
    },
    setScroll(){
      //先重置状态和数组
      this.selects = []
      this.allChecked = false
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if (that.allChecked && that.selects.length == that.tableData.length) {
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    handleClick(tab) {
      this.listQuery.accountingType = tab.props.name == "税款申报" ? 2 : 1
      this.getList()
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.allChecked = true
      }else{
        this.selects = []
        this.allChecked = false
      }
    },
    // 表格排序
    sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.desc = 1
      }else{
        this.listQuery.desc = 0
      }
      this.getList()
    },
  }
}
</script>

<style lang="scss" scoped>
.top_btns{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
