<template>
  <div style="position: relative">
    <div class="tab_filter">
      <span class="hint"
        ><i class="iconfont icon-gantanhao1"></i
        >友情提示:使用"勾选认证"功能,需要下载税局小助手</span
      >
      <el-button
        icon="Pointer"
        size="small"
        type="primary"
        style="margin-right: 10px"
        @click="openwin()"
        >去下载</el-button
      >
      <span style="font-size: 14px; margin-left: 4px">账期选择：</span
      ><qzf-search-period
        v-model:period="listQuery.period"
        @success="getList"
      ></qzf-search-period>
      <selectuser
        code="bookkeeping"
        v-model:userId="listQuery.userId"
        @success="getList()"
        style="margin-right: 0; width: 170px"
      ></selectuser>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <div class="top_select">
        <div>
          <el-button
            icon="RefreshRight"
            size="small"
            style="margin-right: 5px"
            @click="getList()"
          ></el-button>
          <el-input
            size="small"
            placeholder="请输入公司名称/编号"
            v-model.trim="listQuery.name"
            style="width: 210px"
            @keyup.enter="getList"
            clearable
          ></el-input>
          <el-button
            size="small"
            type="primary"
            @click="getList"
            style="margin-right: 10px"
            icon="Search"
            >搜索
          </el-button>
          <search @success="getList" @cancel="cancel" name="状态筛选">
            <el-form
              style="margin: 11px 0px 0px 15px; width: 400px"
              class="styleForm"
              label-width="105px"
            >
              <el-form-item label="税局：">
                <selectcity
                  v-model:citys="listQuery.districtCode"
                  style="width: 140px; margin: 0"
                ></selectcity>
              </el-form-item>
              <el-form-item label="登录状态 ：" :label-width="formLabelWidth">
                <selectAccreditStatus
                  v-model:type="listQuery.keepStatus"
                  style="width: 140px; margin: 0"
                ></selectAccreditStatus>
              </el-form-item>
              <el-form-item label="公司类型：">
                <el-select
                  v-model="listQuery.companyType"
                  placeholder="请选择公司类型"
                  size="small"
                  filterable
                  clearable
                  style="width: 140px; margin: 0"
                >
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="确认状态：">
                <el-checkbox-group size="small" v-model="listQuery.allStatus">
                  <el-checkbox-button
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.value"
                    >{{ item.label }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </search>
          <span class="hint ml-10">
            <i class="iconfont icon-gantanhao1"></i>
            <span>温馨提示：本界面只显示一般纳税人</span>
          </span>
        </div>
        <div>
          <el-button
            size="small"
            type="primary"
            style="margin-left: 12px"
            @click="batchSure"
            :disabled="!$buttonStatus('gxrz_rzfpqr')"
            :loading="btnLoading"
            icon="Check"
          >
            认证发票确认
          </el-button>
          <el-button
            size="small"
            type="primary"
            @click="daochu"
            plain
            :disabled="!$buttonStatus('gxrz_dc')"
            icon="Folder"
          >
            导出
          </el-button>
        </div>
      </div>
      <el-tab-pane label="税款申报" name="first"></el-tab-pane>
      <el-tab-pane label="零申报" name="second"></el-tab-pane>
      <el-table
        stripe
        :data="list"
        :height="contentStyleObj"
        style="width: 100%"
        border
        @select="handleSelectionChange"
        v-loading="loading"
        @sort-change="sortChange"
        ref="tableScroll"
        id="tableLazyLoad"
        @select-all="handleSelectionChangeAll"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column
          label="编号"
          align="center"
          prop="sort"
          width="80"
          fixed="left"
          sortable="custom"
        >
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          fixed="left"
          label="公司名称"
          min-width="300"
        >
          <template #default="scope">
            <TagNameCopy :row="scope.row" idKey="comId"></TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column prop="district" label="税局" width="65" align="center">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district) }}</span>
          </template>
        </el-table-column>

        <el-table-column label="确认状态" width="100">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i
                  :class="
                    $batchSendIconSb(
                      scope.row.taskStatus,
                      scope.row.bussinessStatus
                    )
                  "
                ></i>
                <el-tooltip
                  v-if="scope.row.bussinessLog"
                  effect="dark"
                  placement="top-start"
                >
                  <template #content>
                    <div
                      v-html="
                        scope.row.bussinessLog
                          ? scope.row.bussinessLog
                          : '' + scope.row.errLog
                          ? scope.row.errLog
                          : ''
                      "
                    ></div>
                  </template>
                  <span>
                    {{
                      $batchSendGxrz(
                        scope.row.taskStatus,
                        scope.row.bussinessStatus
                      )
                    }}
                    <i class="iconfont icon-wenhao" style="color: #f56c6c"></i>
                  </span>
                </el-tooltip>
                <span v-else>
                  <span>{{
                    $batchSendGxrz(
                      scope.row.taskStatus,
                      scope.row.bussinessStatus
                    )
                  }}</span>
                </span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="数量"
          align="center"
          width="120"
          prop="inInvoiceNum"
        >
        </el-table-column>
        <el-table-column
          label="金额"
          align="center"
          width="100"
          prop="taxTotal"
        >
        </el-table-column>
        <el-table-column
          label="进项税额"
          align="center"
          width="120"
          prop="periodInTax"
        >
        </el-table-column>
        <el-table-column
          label="确认时间"
          align="center"
          width="150"
          prop="endAt"
        >
        </el-table-column>
        <el-table-column
          prop="jotDown"
          label="随手记"
          min-width="150"
          show-overflow-tooltip
        >
          <template #default="scope">
            <span
              class="xgmStyle"
              style="cursor: pointer"
              @click="smallNotes(scope.row)"
              >记</span
            >
            <span>{{ scope.row.jotDown }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="270">
          <template #default="scope">
            <taxBureau :comId="scope.row.comId"  @success="getList" from="fpdk" label="跳转勾选" :link="true"/>
            <!-- <el-button
              type="primary"
              size="small"
              @click="InTax(scope.row.comId)"
              :disabled="!$buttonStatus('gxrz_tzgx')"
              link
              >跳转勾选</el-button
            > -->
            <!-- <el-button
              type="primary"
              size="small"
              @click="checkWrz(scope.row)"
              :disabled="!$buttonStatus('gxrz_tzgx')"
              link
              >勾选并确认</el-button
            > -->
            <!-- <el-button  type="primary"  size="small" @click="sendRz(scope.row.comId)">确认</el-button> -->
            <el-button
              type="primary"
              size="small"
              @click="declareDetail(scope.row)"
              :disabled="!$buttonStatus('gxrz_qrjl')"
              link
              >确认记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination
          v-show="total > 0"
          :total="total"
          v-model:page="listQuery.page"
          v-model:limit="listQuery.limit"
          @pagination="getList"
          type="checkCerti"
        />
      </div>
    </el-tabs>
  </div>
  <declareDetails ref="declareDetails"></declareDetails>
  <jotDown ref="jotDown" @jotDownUpdate="updateNote" />

  <!-- 税局小助手未安装提示 -->
  <loginTaxAlert ref="loginTaxAlert"></loginTaxAlert>
  <!-- 勾选并确认弹窗 -->
  <invoiceCerti
    ref="invoiceCerti"
    :period="listQuery.period"
    @success="getList"
  />
</template>

<script>
import { companyCheckCertified } from "@/api/company";
import { sendTask } from "@/api/collection";
import { currentAccountPeriod } from "@/utils";
import declareDetails from "./components/declareDetails.vue";
import { wbVoucherFxOther } from "@/api/export";
import { loginTaxInfo } from "@/api/company";
import selectcity from "@/components/Screening/selectcity";
import axios from "axios";
import jotDown from "@/components/jotDown";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import { COMPANY_TYPE_OPTION } from "@/utils/commonData.js";
import invoiceCerti from "./components/invoiceCerti.vue";
import taxBureau from "@/components/loginTaxAlert/taxBureau.vue"

export default {
  name: "checkCerti",
  components: {
    declareDetails,
    selectcity,
    selectAccreditStatus,
    jotDown,
    TagNameCopy,
    TableSortCell,
    invoiceCerti,
    taxBureau
  },
  props: {},
  data() {
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      listQuery: {
        page: 1,
        limit: 20,
        period: currentAccountPeriod(),
        accountingType: 2,
        name: "",
        status: -1,
        userId: null,
        companyType: "",
      },
      list: [],
      contentStyleObj: {},
      loading: false,
      activeName: "first",
      total: 0,
      selects: [],
      statusOptions: [
        {
          value: "task-0",
          label: "未确认",
        },
        {
          value: "task-99",
          label: "发起中",
        },
        {
          value: "task-1",
          label: "排队中",
        },
        {
          value: "task-2",
          label: "确认中",
        },
        {
          value: "task-8",
          label: "任务已撤销",
        },
        {
          value: "business-2",
          label: "确认成功",
        },
        {
          value: "business-3",
          label: "确认失败",
        },
      ],
      btnLoading: false,
      totalPage: 0,
      pageSize: 35,
      tableData: [],
      currentPage: 1,
      allChecked: false,
    };
  },
  mounted() {
    this.listQuery.limit = localStorage.getItem("checkCerti")
      ? localStorage.getItem("checkCerti") * 1
      : 20;
    this.contentStyleObj = this.$getHeight(245);
    this.getList();
  },
  methods: {
    smallNotes(row) {
      this.$refs.jotDown.init(row.name, row.comId, this.listQuery.period);
    },
    //更新随手记之后的回调
    updateNote(id, text) {
      this.list.map((v) => {
        if (v.comId == id) {
          v.jotDown = text;
        }
      });
    },
    // 重置
    cancel() {
      let originLimit = this.listQuery.limit;
      let originAccountingType = this.listQuery.accountingType;
      this.listQuery = {
        page: 1,
        limit: originLimit,
        period: currentAccountPeriod(),
        accountingType: originAccountingType,
        name: "",
        status: -1,
        userId: null,
        districtCode: null,
        allStatus: [],
        companyType: "",
      };
      this.getList();
    },
    openwin() {
      window.open(
        "https://file.listensoft.net/mnt/down/%E7%A8%8E%E5%B1%80%E5%B0%8F%E5%8A%A9%E6%89%8B.exe"
      );
    },
    getList() {
      this.loading = true;
      companyCheckCertified(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
          this.setScroll();
        }
      });
    },
    setScroll() {
      //先重置状态和数组
      this.allChecked = false;
      this.selects = [];
      //计算滚动页数
      this.totalPage = this.tableData.length / this.pageSize;
      if (this.tableData.length % this.pageSize == 0) {
        this.totalPage = this.tableData.length / this.pageSize;
      } else {
        this.totalPage = parseInt(this.tableData.length / this.pageSize) + 1;
      }
      this.list = this.tableData.slice(0, this.currentPage * this.pageSize);
      this.lazyLoading();
    },
    lazyLoading() {
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this;
      dom.addEventListener("scroll", function () {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if (
              that.allChecked &&
              that.selects.length == that.tableData.length
            ) {
              that.$refs.tableScroll.toggleAllSelection();
              that.allChecked = true;
            } else if (that.selects.length != 0) {
              that.selects.map((v) => {
                that.list.map((item) => {
                  if (item.comId == v.comId) {
                    that.$nextTick(() => {
                      that.$refs.tableScroll.toggleRowSelection(item, true);
                    });
                  }
                });
              });
            }
          }
        }
      });
    },
    handleSelectionChange(val) {
      if (this.allChecked && this.list.length != this.tableData.length) {
        let currentIndex = 0;
        this.list.map((v, i2) => {
          val.map((e) => {
            if (e.comId == v.comId) {
              currentIndex = i2;
            }
          });
        });
        let arr = this.tableData.slice(currentIndex + 1, this.tableData.length);
        let newArr = [...val, ...arr];
        this.selects = newArr;
      } else {
        this.selects = val;
      }
    },
    handleSelectionChangeAll(e) {
      if (e.length != 0) {
        this.selects = this.tableData;
        this.allChecked = true;
      } else {
        this.selects = [];
        this.allChecked = false;
      }
    },
    //勾选并确认
    checkWrz(row) {
      this.$refs.invoiceCerti.init(row);
    },
    //勾选认证
    InTax(id) {
      this.$qzfMessage("浏览器启动中,预计需要15秒......");
      let that = this;
      loginTaxInfo({ id: id }).then((res) => {
        let param = res.data.data.info;
        axios
          .post("http://localhost:9111/loginTax/fpdk", param)
          .then((res) => {
            // that.fullscreenLoading = false
            if (res.data.msg != "success") {
              that.$alert(res.data.msg, "提示", {
                confirmButtonText: "确定",
                confirmButtonClass: "ExitConfirmButton",
              });
            }
          })
          .catch(function () {
            setTimeout(() => {
              that.$refs.loginTaxAlert.dialogVisible = true;
            }, 1000);
          });
      });
    },
    sendRz(row) {
      let param = {
        comIds: [row.comId],
        period: this.listQuery.period,
        taskName: "tax-gxrz",
      };
      sendTask(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("任务已发起", 3);
          this.getList();
        }
      });
    },
    //批量确认
    batchSure() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      this.btnLoading = true;
      let ids = [];
      this.selects.map((v) => {
        ids.push(v.comId);
      });
      let param = {
        comIds: ids,
        period: this.listQuery.period,
        taskName: "tax-gxrz",
      };
      sendTask(param).then((res) => {
        this.btnLoading = false;
        if (res.data.msg == "success") {
          this.$qzfMessage("发起成功");
          this.getList();
        }
      });
    },
    // 认证详情
    declareDetail(e) {
      this.$refs.declareDetails.init(
        e.comId,
        this.listQuery.period,
        e.taskName
      );
    },
    //导出
    daochu() {
      let ids = [];
      this.selects.map((v) => {
        ids.push(v.comId);
      });
      let param = {
        outType: "excel",
        exportType: "company_check_certified",
        period: this.listQuery.period,
        query: this.listQuery,
      };
      param.query.comIds = ids;
      wbVoucherFxOther(param).then((res) => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
    // 表格排序
    sortChange(data) {
      if (data.order == "descending") {
        this.listQuery.desc = 1;
      } else {
        this.listQuery.desc = 0;
      }
      this.getList();
    },
    handleClick(tab) {
      if (tab.props.label == "零申报") {
        this.listQuery.accountingType = 1;
      } else if (tab.props.label == "税款申报") {
        this.listQuery.accountingType = 2;
      }
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
a {
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
  text-decoration: none;
}

.item_icon {
  display: inline-block;
  cursor: pointer;
  i {
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 4px;
  }
  p {
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
.tab_filter {
  text-align: right;
  position: absolute;
  right: 18px;
  top: 8px;
  z-index: 99;
}
.hint {
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
  margin-right: 10px;
  .icon-gantanhao1 {
    font-size: 13px;
  }
}
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
